import { RoleRequest, RoleResponse } from '@/models/UserRole';
import api from './api';
import { Pageable, PageableResponse } from 'Pageable';
import permissions from '@/data/permissions.json'

export function useRoleService() {

    const fetchRoles = async (pageable: Pageable): Promise<PageableResponse<RoleResponse>> => {
        try {
          const response =
            await
              api.get(`roles?page=${pageable.pageNumber - 1}&size=${pageable.pageSize}&sort=id`
                , { withCredentials: true });
          return response.data;
        } catch (error) {
          return Promise.reject(error);
        }
      };

      const fetchById =async (role:RoleResponse) => {
        try {
            const response = await api.get('roles/'+role.id, { withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const create =async (role:RoleRequest) => {
        try {
            const response = await api.post('roles', role, { withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const update = async (role:RoleRequest) => {
        try {
            const response = await api.put('roles/'+role.id, role, { withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const remove = async (role:RoleResponse) => {
        try {
            const response = await api.delete('roles/'+role.id,{ withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const fetchRoleList = async () => {
        try {
            const response = await api.get('roles/list', { withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const fetchAllPermissions = () =>{
        return permissions; //TODO replace with endpoint
    };
    return { fetchRoles,fetchById,create,update,remove,fetchRoleList,fetchAllPermissions }
}