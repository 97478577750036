
import { defineComponent } from "vue";
import "leaflet/dist/leaflet.css";
import { LMap, LTileLayer } from "@vue-leaflet/vue-leaflet";

export default defineComponent({
  name: "LeafletMap",
  components: {
    LMap,
    LTileLayer,
  },
});
