
import { defineComponent } from "vue";
import SideBar from "../SideBar.vue";

export default defineComponent({
  name: "ActivityContent",
  components: {
    SideBar,
  },
});
