export const ConvertDateToDateTime = (dateInput: Date):Date | null =>{

    const date = new Date(dateInput);

      // Check if the date is valid
      if (!isNaN(date.getTime())) {
        // If date is valid, format it as needed
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
        return new Date(formattedDate);
      }
      return null;

}

export const FormatDate = (dateInput: Date):string =>{
    const date = new Date(dateInput);

    // Check if the date is valid
    if (!isNaN(date.getTime())) {
      // If date is valid, format it as needed
      const year = date.getFullYear();
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const day = date.getDate().toString().padStart(2, "0");
      return `${day}.${month}.${year}`;
    }
    return "";
}