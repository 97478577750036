
import { defineComponent } from "vue";
import BasicAlerts from "./BasicAlerts.vue";
import DismissingAlerts from "./DismissingAlerts.vue";
import IconsAlerts from "./IconsAlerts.vue";
import AdditionalContentAlerts from "./AdditionalContentAlerts.vue";

export default defineComponent({
  name: "AlertsContent",
  components: {
    BasicAlerts,
    DismissingAlerts,
    IconsAlerts,
    AdditionalContentAlerts,
  },
});
