
import { defineComponent } from "vue";
import revenueTargetByCountry from "./revenueTargetByCountry.json";

export default defineComponent({
  name: "RevenueTargetByCountry",
  data() {
    return {
      revenues: revenueTargetByCountry,
    };
  },
});
