
import { defineComponent } from "vue";

export default defineComponent({
  name: "ExpectedEarnings",
  data: function () {
    return {
      expected: [50, 35, 45],
      expectedEraningsChart: {
        chart: {
          height: 150,
          type: "donut",
        },
        dataLabels: {
          enabled: false,
        },
        colors: ["#06B48A", "#6560F0", "#6FD3F7"],
        legend: {
          show: false,
          fontWeight: 500,
          fontSize: "14px",
          fontFamily: "Red Hat Display, sans-serif",
          labels: {
            colors: "#9C9AB6",
          },
          markers: {
            offsetX: -2,
            offsetY: 1,
          },
        },
        stroke: {
          width: 0,
        },
        tooltip: {
          enabled: false,
          style: {
            fontSize: "14px",
            fontFamily: "Red Hat Display, sans-serif",
          },
        },
      },
    };
  },
});
