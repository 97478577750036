import { createI18n } from 'vue-i18n';


import enMessages from './en.json';
import alMessages from './al.json';
import srMessages from './sr.json';

export const i18n = createI18n({
    legacy: false, 
    locale: 'en',
    fallbackLocale: 'en',
    messages: {
      en: enMessages,
      al: alMessages,
      sr: srMessages
    },
  });