
import { defineComponent, ref, onMounted } from "vue";

export default defineComponent({
  name: "WhatHappening",
  data() {
    return {
      updates: [
        {
          id: 1,
          bgClass: "bg-f2f1f9",
          title: "COURSES",
          class: "text-primary",
          number: "23",
        },
        {
          id: 2,
          bgClass: "bg-faf7f7",
          title: "CERTIFICATES",
          class: "text-danger",
          number: "9",
        },
        {
          id: 3,
          bgClass: "bg-ecf3f2",
          title: "SCORE",
          class: "text-success",
          number: "4.8",
        },
        {
          id: 4,
          bgClass: "bg-f3f7f9",
          title: "HRS LEARNED",
          class: "text-info",
          number: "822",
        },
      ],
    };
  },
  setup() {
    const formattedDate = ref("");

    onMounted(() => {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const currentDate = new Date();
      formattedDate.value = `${
        months[currentDate.getMonth()]
      } ${currentDate.getDate()}, ${currentDate.getFullYear()}`;
    });

    return {
      formattedDate,
    };
  },
});
