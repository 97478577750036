import { Party } from '@/models/Party';
import api from './api';
import { Pageable, PageableResponse } from 'Pageable';

export function usePartyService() {

    const fetchPage = async (pageable: Pageable): Promise<PageableResponse<Party>> => {
        try {
          const response =
            await
              api.get(`party?page=${pageable.pageNumber - 1}&size=${pageable.pageSize}&sort=id`
                , { withCredentials: true });
                console.log(response.data);
          return response.data;
        } catch (error) {
          return Promise.reject(error);
        }
      };

      const fetchById = async (party:Party) => {
        try {
            const response = await api.get('party/'+party.id, { withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const create =async (party:Party) => {
        const date = convertDate(party.dateOfBirth);
        if(!date){
            console.log("Invalid date")
            return;
        }
        party.dateOfBirth = date;
        try {
            const response = await api.post('party', party, { withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const update = async (party:Party) => {
        const date = convertDate(party.dateOfBirth);
        if(!date){
            console.log("Invalid date")
            return;
        }
        party.dateOfBirth = date;
        try {
            const response = await api.put('party/'+party.id, party, { withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const remove = async (party:Party) => {
        try {
            const response = await api.delete('party/'+party.id,{ withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const fetchList = async () => {
        try {
            const response = await api.get('party/list', { withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const convertDate = (dateInput: Date | null) =>{
        const dateOfBirthString = dateInput;
    if (dateOfBirthString) {
      // Convert the dateOfBirthString into a Date object
      const date = new Date(dateOfBirthString);

      // Check if the date is valid
      if (!isNaN(date.getTime())) {
        // If date is valid, format it as needed
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        // Update the dateOfBirth property in the object
        return new Date(formattedDate);
      } else {
        // Handle invalid dateOfBirthString (e.g., show an error message)
        console.error("Invalid dateOfBirthString");
        return null;
      }
    }
}

    return { fetchPage,fetchById,create,update,remove,fetchList }
}