
import { defineComponent } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import StatsBoxes from "../../components/Widgets/Cards/StatsBoxes/index.vue";
import StatsItem from "../../components/Widgets/Cards/StatsItem.vue";
import WhatHappening from "../../components/Widgets/Cards/WhatHappening.vue";
import ResolveComplaints from "../../components/Widgets/Cards/ResolveComplaints.vue";
import AverageSpeedOfAnswer from "../../components/Widgets/Cards/AverageSpeedOfAnswer.vue";
import NewContact from "../../components/Widgets/Cards/NewContact.vue";
import NewUsers from "../../components/Widgets/Cards/NewUsers.vue";
import NewLeads from "../../components/Widgets/Cards/NewLeads.vue";
import CardsStyle from "../../components/Widgets/Cards/CardsStyle/index.vue";
import CardsStyleTwo from "../../components/Widgets/Cards/CardsStyleTwo/index.vue";
import CardsStyleThree from "../../components/Widgets/Cards/CardsStyleThree/index.vue";
import CardsStyleFour from "../../components/Widgets/Cards/CardsStyleFour/index.vue";
import TimelineCard from "../../components/Widgets/Cards/TimelineCard.vue";
import HandlingProjects from "../../components/Widgets/Cards/HandlingProjects.vue";
import TeamMember from "../../components/Widgets/Cards/TeamMember.vue";
import StoreContent from "../../components/Widgets/Cards/StoreContent.vue";
import WhatsHappening from "../../components/Widgets/Cards/WhatsHappening.vue";

export default defineComponent({
  name: "CardsPage",
  components: {
    BreadCrumb,
    StatsBoxes,
    StatsItem,
    WhatHappening,
    ResolveComplaints,
    AverageSpeedOfAnswer,
    NewContact,
    NewUsers,
    NewLeads,
    CardsStyle,
    CardsStyleTwo,
    CardsStyleThree,
    CardsStyleFour,
    TimelineCard,
    HandlingProjects,
    TeamMember,
    StoreContent,
    WhatsHappening,
  },
});
