import { CaseStatus } from '@/models/Case';
import api from './api';
import { Pageable, PageableResponse } from 'Pageable';

export function useCaseStatusService() {

    const fetchPage = async (pageable: Pageable): Promise<PageableResponse<CaseStatus>> => {
        try {
          const response =
            await
              api.get(`case-status?page=${pageable.pageNumber - 1}&size=${pageable.pageSize}&sort=id`
                , { withCredentials: true });
                console.log(response.data);
          return response.data;
        } catch (error) {
          return Promise.reject(error);
        }
      };

      const fetchById =async (caseStatus:CaseStatus) => {
        try {
            const response = await api.get('case-status/'+caseStatus.id, { withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const create =async (caseStatus:CaseStatus) => {
        try {
            const response = await api.post('case-status', caseStatus, { withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const update = async (caseStatus:CaseStatus) => {
        try {
            const response = await api.put('case-status/'+caseStatus.id, caseStatus, { withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const remove = async (caseStatus:CaseStatus) => {
        try {
            const response = await api.delete('case-status/'+caseStatus.id,{ withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const fetchList = async () => {
        try {
            const response = await api.get('case-status/list', { withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };
    return { fetchPage,fetchById,create,update,remove,fetchList }
}