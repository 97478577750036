
import { defineComponent } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import NavsTabs from "../../components/UIElements/NavsTabs/NavsTabs.vue";

export default defineComponent({
  name: "NavsTabsPage",
  components: {
    BreadCrumb,
    NavsTabs,
  },
});
