
import { defineComponent, ref } from "vue";
import Tree from "vue3-tree";
import "vue3-tree/dist/style.css";

export default defineComponent({
  name: "TreeContent",
  components: {
    Tree,
  },
  setup() {
    const data = ref([
      {
        id: 1,
        label: "node-0",
        nodes: [
          {
            id: 1,
            label: "node-0-0",
            nodes: [
              {
                id: "0-0-0",
                label: "node-0-0-0",
              },
              {
                id: "0-0-1",
                label: "node-0-0-1",
              },
              {
                id: "0-0-2",
                label: "node-0-0-2",
              },
            ],
          },
          {
            id: 2,
            label: "node-0-1",
            nodes: [
              {
                id: "0-1-0",
                label: "node-0-1-0",
              },
              { id: "0-1-1", label: "node-0-1-1" },
              {
                id: "0-1-2",
                label: "node-0-1-2",
              },
            ],
          },
          {
            id: 3,
            label: "node-0-2",
            nodes: [
              {
                id: "0-2-0",
                label: "node-0-2-0",
              },
              {
                id: "0-2-1",
                label: "node-0-2-1",
              },
              {
                id: "0-2-2",
                label: "node-0-2-2",
              },
            ],
          },
        ],
      },
      {
        id: 2,
        label: "node-1",
        nodes: [
          {
            id: 1,
            label: "node-1-0",
            nodes: [
              {
                id: "1-0-0",
                label: "node-1-0-0",
              },
              {
                id: "1-0-1",
                label: "node-1-0-1",
              },
              {
                id: "1-0-2",
                label: "node-1-0-2",
              },
            ],
          },
          {
            id: 2,
            label: "node-1-1",
            nodes: [
              {
                id: "1-1-0",
                label: "node-1-1-0",
              },
              {
                id: "1-1-1",
                label: "node-1-1-1",
              },
              {
                id: "1-1-2",
                label: "node-1-1-2",
              },
            ],
          },
          {
            id: 3,
            label: "node-1-2",
            nodes: [
              {
                id: "1-2-0",
                label: "node-1-2-0",
              },
              {
                id: "1-2-1",
                label: "node-1-2-1",
              },
              {
                id: "1-2-2",
                label: "node-1-2-2",
              },
            ],
          },
        ],
      },
      {
        id: 3,
        label: "node-2",
        nodes: [
          {
            id: 1,
            label: "node-2-0",
            nodes: [
              {
                id: "2-0-0",
                label: "node-2-0-0",
              },
              {
                id: "2-0-1",
                label: "node-2-0-1",
              },
              {
                id: "2-0-2",
                label: "node-2-0-2",
              },
            ],
          },
          {
            id: 2,
            label: "node-2-1",
            nodes: [
              {
                id: "2-1-0",
                label: "node-2-1-0",
              },
              {
                id: "2-1-1",
                label: "node-2-1-1",
              },
              {
                id: "2-1-2",
                label: "node-2-1-2",
              },
            ],
          },
          {
            id: 3,
            label: "node-2-2",
            nodes: [
              {
                id: "2-2-0",
                label: "node-2-2-0",
              },
              {
                id: "2-2-1",
                label: "node-2-2-1",
              },
              {
                id: "2-2-2",
                label: "node-2-2-2",
              },
            ],
          },
        ],
      },
    ]);
    const onNodeExpanded = (node, state) => {
      console.log("state: ", state);
      console.log("node: ", node);
    };

    const onUpdate = (nodes) => {
      console.log("nodes:", nodes);
    };

    const onNodeClick = (node) => {
      console.log(node);
    };

    return {
      data,
      onNodeExpanded,
      onUpdate,
      onNodeClick,
    };
  },
});
