
import { defineComponent } from "vue";
import topPerformerHelpAgent from "./topPerformerHelpAgent.json";

export default defineComponent({
  name: "TopPerformerHelpAgent",
  data() {
    return {
      performers: topPerformerHelpAgent,
    };
  },
});
