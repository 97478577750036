
import { defineComponent } from "vue";

export default defineComponent({
  name: "CustomerSatisfaction",
  data: function () {
    return {
      customer: [90, 10],
      customerSatisfactionChart: {
        chart: {
          height: 240,
          type: "pie",
        },
        labels: ["Positive", "Negative"],
        colors: ["#6560F0", "#F1421B"],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 0,
        },
        legend: {
          show: false,
        },
        tooltip: {
          style: {
            fontSize: "14px",
            fontFamily: "Red Hat Display, sans-serif",
          },
        },
      },
    };
  },
});
