import axios, { AxiosInstance } from "axios";

const instance: AxiosInstance = axios.create({
  baseURL: "https://www.leafytech.dev/",
  headers: {
    "Content-Type": "application/json",
  },
});

export default instance;
