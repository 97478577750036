import { UserLogin, UserRegister } from '@/models/User';
import api from './api';
import router from '@/router';

export function useAuthService() {
  const loginUser = async (user:UserLogin) => {
    try {
      const response = await api.post('login', user,{withCredentials:true});
      if (response.status === 200) {
        localStorage.setItem('user', JSON.stringify(response.data));
      }
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const logoutUser = () => {
    localStorage.removeItem('user');
  };

  const registerUser = async (registerDto: UserRegister) => {
    try {
      const dateOfBirthString = registerDto.dateOfBirth;
      if (dateOfBirthString) {
        // Convert the dateOfBirthString into a Date object
        const date = new Date(dateOfBirthString);
  
        // Check if the date is valid
        if (!isNaN(date.getTime())) {
          // If date is valid, format it as needed
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, "0");
          const day = date.getDate().toString().padStart(2, "0");
          const hours = date.getHours().toString().padStart(2, "0");
          const minutes = date.getMinutes().toString().padStart(2, "0");
          const seconds = date.getSeconds().toString().padStart(2, "0");
          const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  
          // Update the dateOfBirth property in the registerDto
          registerDto.dateOfBirth = new Date(formattedDate);
        } else {
          // Handle invalid dateOfBirthString (e.g., show an error message)
          console.error("Invalid dateOfBirthString");
        }
      }
  
      const response = await api.post('register', registerDto);
      if (response.status === 200) {
        router.push('/');
      }
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };
  

  return {
    loginUser,
    logoutUser,
    registerUser,
  };
}