
import { defineComponent } from "vue";
import cardsStyleFour from "./cardsStyleFour.json";

export default defineComponent({
  name: "CardsStyleFour",
  data() {
    return {
      cards: cardsStyleFour,
    };
  },
});
