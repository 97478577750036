import { CaseStatus, CaseType } from "@/models/Case";

export const GetLocaleCaseType = (caseType: CaseType | null, locale:string): string | null => {
    if (!caseType) {
        return null;
    }
    switch (locale) {
        case "en":
            return caseType.typeEn;
        case "al":
            return  caseType.typeAl;
        case "srb":
            return  caseType.typeSrb;
        default:
            return null;
    }
};

export const GetLocaleCaseStatus = (caseStatus: CaseStatus | null, locale:string): string | null => {
    if (!caseStatus) {
        return null;
    }
    switch (locale) {
        case "en":
            return caseStatus.statusEn;
        case "al":
            return  caseStatus.statusAl;
        case "srb":
            return  caseStatus.statusSrb;
        default:
            return null;
    }
};