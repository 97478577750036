
import { defineComponent } from "vue";
import fundamentalCourses from "./fundamentalCourses.json";

export default defineComponent({
  name: "FundamentalCourses",
  data() {
    return {
      courses: fundamentalCourses,
    };
  },
});
