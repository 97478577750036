
import { defineComponent } from "vue";
import BasicAccordion from "./BasicAccordion.vue";
import FlushAccordion from "./FlushAccordion.vue";
import AlwaysOpenAccordion from "./AlwaysOpenAccordion.vue";

export default defineComponent({
  name: "AccordionContent",
  components: {
    BasicAccordion,
    FlushAccordion,
    AlwaysOpenAccordion,
  },
});
