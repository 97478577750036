import { Locale } from "vue-i18n";
import { Party } from "./Party";

export interface CaseType {
    id:number;
    typeEn:string;
    typeAl:string;
    typeSrb:string;
}

export interface CaseTypeDto {
    id:number;
    type:string;
}

export interface CaseStatus {
    id:number;
    statusEn:string;
    statusAl:string;
    statusSrb:string;
}

export interface CaseStatusDto {
    id:number;
    status:string;
}

export interface Case {
    id: number;
    title: string;
    description: string;
    filedDate: Date | null;
    judge: string;
    caseNumber: string;
    caseStatus: CaseStatus | null;
    caseType: CaseType | null;
    parties: Party[];
}

const mapCaseStatusToDto = (caseStatus: CaseStatus | null,locale:string): CaseStatusDto | null => {
    if (!caseStatus) {
        return null;
    }
    switch (locale) {
        case "en":
            return { id: caseStatus.id, status: caseStatus.statusEn };
        case "al":
            return { id: caseStatus.id, status: caseStatus.statusAl };
        case "srb":
            return { id: caseStatus.id, status: caseStatus.statusSrb };
        default:
            return null;
    }
};

 const mapCaseTypeToDto = (caseType: CaseType | null, locale:string): CaseTypeDto | null => {
    if (!caseType) {
        return null;
    }
    switch (locale) {
        case "en":
            return { id: caseType.id, type: caseType.typeEn };
        case "al":
            return { id: caseType.id, type: caseType.typeAl };
        case "srb":
            return { id: caseType.id, type: caseType.typeSrb };
        default:
            return null;
    }
};

export const caseStatusArraytoDto = (caseStatuses: CaseStatus[] | null,locale:Locale ): CaseStatusDto[] =>{
    if(!caseStatuses){
        return [];
    }
    const newArray:CaseStatusDto[] =[];
    caseStatuses.forEach((status) => {
        const tempStatus = mapCaseStatusToDto(status, locale);
        if(tempStatus){
            newArray.push(tempStatus);
        }
    });
    return newArray;
}

export const caseTypeArraytoDto = (caseTypes: CaseType[] | null,locale:Locale ): CaseTypeDto[]  =>{
    if(!caseTypes){
        return [];
    }
    const newArray:CaseTypeDto[] = [];
    caseTypes.forEach((type) => {
        const temp = mapCaseTypeToDto(type, locale)
        if(temp){
            newArray.push(temp);
        }
    });
    return newArray;
}

