
import { defineComponent } from "vue";

export default defineComponent({
  name: "NewUsers",
  data: function () {
    return {
      users: [
        {
          data: [7, 8, 9, 9, 8, 9, 8, 9, 11, 9, 8],
        },
      ],
      newUsersChart: {
        chart: {
          height: 135,
          type: "area",
          toolbar: {
            show: false,
          },
        },
        colors: ["#6FD3F7"],
        dataLabels: {
          enabled: false,
        },
        xaxis: {
          labels: {
            show: false,
          },
          axisBorder: {
            show: false,
          },
          axisTicks: {
            show: false,
          },
          tooltip: {
            enabled: false,
          },
        },
        yaxis: {
          show: false,
        },
        grid: {
          show: false,
          strokeDashArray: 5,
          borderColor: "#f0f0f0",
        },
        tooltip: {
          enabled: false,
          style: {
            fontSize: "14px",
            fontFamily: "Red Hat Display, sans-serif",
          },
        },
      },
    };
  },
});
