import { UserRegister, UserResponse } from '@/models/User';
import { Pageable, PageableResponse } from 'Pageable';
import api from './api';
import { decodeJWT } from '@/Utils/JwtUtil';

export function useUserService() {
  const fetchUsers = async (pageable: Pageable): Promise<PageableResponse<UserResponse>> => {
    try {
      const response =
        await
          api.get(`users?page=${pageable.pageNumber - 1}&size=${pageable.pageSize}&sort=id`
            , { withCredentials: true });
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  };

  const create = async (user: UserRegister) => {
      user.id =0;
      const dateOfBirthString = user.dateOfBirth;
      if (dateOfBirthString) {
        // Convert the dateOfBirthString into a Date object
        const date = new Date(dateOfBirthString);
  
        // Check if the date is valid
        if (!isNaN(date.getTime())) {
          // If date is valid, format it as needed
          const year = date.getFullYear();
          const month = (date.getMonth() + 1).toString().padStart(2, "0");
          const day = date.getDate().toString().padStart(2, "0");
          const hours = date.getHours().toString().padStart(2, "0");
          const minutes = date.getMinutes().toString().padStart(2, "0");
          const seconds = date.getSeconds().toString().padStart(2, "0");
          const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  
          // Update the dateOfBirth property in the registerDto
          user.dateOfBirth = new Date(formattedDate);
        } else {
          // Handle invalid dateOfBirthString (e.g., show an error message)
          console.error("Invalid dateOfBirthString");
        }
      }
    try {
      const response = await api.post('users', user,{withCredentials:true});
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  const update = async (user: UserRegister) => {
    const dateOfBirthString = user.dateOfBirth;
    if (dateOfBirthString) {
      // Convert the dateOfBirthString into a Date object
      const date = new Date(dateOfBirthString);

      // Check if the date is valid
      if (!isNaN(date.getTime())) {
        // If date is valid, format it as needed
        const year = date.getFullYear();
        const month = (date.getMonth() + 1).toString().padStart(2, "0");
        const day = date.getDate().toString().padStart(2, "0");
        const hours = date.getHours().toString().padStart(2, "0");
        const minutes = date.getMinutes().toString().padStart(2, "0");
        const seconds = date.getSeconds().toString().padStart(2, "0");
        const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

        // Update the dateOfBirth property in the registerDto
        user.dateOfBirth = new Date(formattedDate);
      } else {
        // Handle invalid dateOfBirthString (e.g., show an error message)
        console.error("Invalid dateOfBirthString");
      }
    }
    try {
      const response = await api.put(`users/${user.id}`, user,{withCredentials:true});
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  const remove = async (user: UserRegister) => {
    try {
      const response = await api.delete(`users/${user.id}`,{withCredentials:true});
      return response.data;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  const verifyUser = async (token: string) => {
    const json = decodeJWT(token);
    const headers = {
      'Authorization': `Bearer ${token}`,
    };

    try {
      const response = await api.get('users/verify?id=' + json.user_id, { headers });
      return response;
    } catch (error) {
      return Promise.reject(error);
    }
  }

  return { fetchUsers, verifyUser, create, update, remove };
}