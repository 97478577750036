
import { defineComponent } from "vue";
import toDoList from "./toDoList.json";

export default defineComponent({
  name: "ToDoList",
  data() {
    return {
      lists: toDoList,
    };
  },
});
