
import { defineComponent } from "vue";
import cardsStyleThree from "./cardsStyleThree.json";

export default defineComponent({
  name: "CardsStyleThree",
  data() {
    return {
      cards: cardsStyleThree,
    };
  },
});
