
import { defineComponent } from "vue";

export default defineComponent({
  name: "ProjectDistribution",
  data: function () {
    return {
      project: [35, 35, 41, 45],
      projectDistributionChart: {
        chart: {
          type: "donut",
          height: 382,
        },
        plotOptions: {
          pie: {
            donut: {
              size: "75%",
            },
          },
        },
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: 4,
        },
        colors: ["#F1421B", "#06B48A", "#6560F0", "#6FD3F7"],
        legend: {
          position: "bottom",
          horizontalAlign: "center",
          fontWeight: 500,
          fontSize: "14px",
          fontFamily: "Red Hat Display, sans-serif",
          labels: {
            colors: "#9C9AB6",
          },
          markers: {
            offsetX: -2,
          },
          itemMargin: {
            horizontal: 8,
            vertical: 5,
          },
        },
        labels: ["Research", "Design", "Frontend", "Backend"],
        tooltip: {
          style: {
            fontSize: "14px",
            fontFamily: "Red Hat Display, sans-serif",
          },
        },
      },
    };
  },
});
