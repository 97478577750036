
import { defineComponent, ref, onMounted } from "vue";

export default defineComponent({
  name: "WhatHappening",
  data() {
    return {
      updates: [
        {
          id: 1,
          bgClass: "bg-f2f1f9",
          title: "UNRESOLVED",
          class: "text-primary",
          number: "523",
        },
        {
          id: 2,
          bgClass: "bg-faf7f7",
          title: "OVER DUE",
          class: "text-danger",
          number: "209",
        },
        {
          id: 3,
          bgClass: "bg-ecf3f2",
          title: "RESOLVED",
          class: "text-success",
          number: "1836",
        },
      ],
    };
  },
  setup() {
    const formattedDate = ref("");

    onMounted(() => {
      const months = [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
      ];

      const currentDate = new Date();
      formattedDate.value = `${
        months[currentDate.getMonth()]
      } ${currentDate.getDate()}, ${currentDate.getFullYear()}`;
    });

    return {
      formattedDate,
    };
  },
});
