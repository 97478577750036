
import { defineComponent } from "vue";
import ImageRoundedCircle from "./ImageRoundedCircle.vue";
import ImageSquareCircle from "./ImageSquareCircle.vue";
import AvatarRoundedCircle from "./AvatarRoundedCircle.vue";
import AvatarSquareCircle from "./AvatarSquareCircle.vue";

export default defineComponent({
  name: "AvatarsContent",
  components: {
    ImageRoundedCircle,
    ImageSquareCircle,
    AvatarRoundedCircle,
    AvatarSquareCircle,
  },
});
