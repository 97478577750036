import { Case } from '@/models/Case';
import api from './api';
import { Pageable, PageableResponse } from 'Pageable';
import { ConvertDateToDateTime } from '@/Utils/DateTimeUtil';

export function useCaseService() {

    const fetchPage = async (pageable: Pageable): Promise<PageableResponse<Case>> => {
        try {
          const response =
            await
              api.get(`cases?page=${pageable.pageNumber - 1}&size=${pageable.pageSize}&sort=id`
                , { withCredentials: true });
                console.log(response.data);
          return response.data;
        } catch (error) {
          return Promise.reject(error);
        }
      };

      const fetchById =async(caseObj:Case) => {
        try {
            const response = await api.get('cases/'+caseObj.id, { withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const create =async (caseObj:Case) => {
        if(caseObj.filedDate){
            caseObj.filedDate = ConvertDateToDateTime(caseObj.filedDate)
        }
        try {
            const response = await api.post('cases', caseObj, { withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const update = async (caseObj:Case) => {
        try {
            const response = await api.put('cases/'+caseObj.id, caseObj, { withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };


    const fetchList = async () => {
        try {
            const response = await api.get('cases/list', { withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };
    return { fetchPage,fetchById,create,update,fetchList }
}