
import { defineComponent } from "vue";

import statsBoxes from "./statsBoxes.json";

export default defineComponent({
  name: "StatsBoxes",
  data() {
    return {
      stats: statsBoxes,
    };
  },
});
