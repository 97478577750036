
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ContactsContent from "../../components/SupportDesk/Contacts/ContactsContent.vue";

export default defineComponent({
  name: "ContactsPage",
  components: {
    BreadCrumb,
    ContactsContent,
  },
});
