import { reactive } from 'vue';
import { UserLogin, UserRegister, UserLoginResponse } from '@/models/User';
import { useAuthService } from '@/service/authService';

interface AuthState {
  loggedIn: boolean;
  user: UserLoginResponse | null;
  getLoggedIn: () => boolean;
  setLoggedIn: (value: boolean) => void;
  getUser: () => UserLoginResponse | null;
  setUser: (userData: UserLoginResponse | null) => void;
  login: (user: UserLogin) => Promise<UserLoginResponse>;
  logout: () => Promise<void>;
  register: (user: UserRegister) => Promise<unknown>;
}

const authService = useAuthService();

const authStore: AuthState = reactive({
  loggedIn: false,
  user: null,
  getLoggedIn(): boolean {
    return authStore.loggedIn;
  },
  setLoggedIn(value: boolean): void {
    authStore.loggedIn = value;
  },
  getUser(): UserLoginResponse | null {
    return authStore.user;
  },
  setUser(userData: UserLoginResponse | null): void {
    authStore.user = userData;
  },
  async login(user: UserLogin): Promise<UserLoginResponse> {
    try {
      const userData: UserLoginResponse = await authService.loginUser(user);
      authStore.setLoggedIn(true);
      authStore.setUser(userData);
      return userData;
    } catch (error) {
      authStore.setLoggedIn(false);
      authStore.setUser(null);
      throw error;
    }
  },
  async logout(): Promise<void> {
      await authService.logoutUser();
      authStore.setLoggedIn(false);
      authStore.setUser(null);
  },
  async register(user: UserRegister): Promise<unknown> {
    try {
      const response = await authService.registerUser(user);
      authStore.setLoggedIn(false);
      authStore.setUser(null);
      return response;
    } catch (error) {
      authStore.setLoggedIn(false);
      throw error;
    }
  }
});

export default authStore;
