
import { defineComponent } from "vue";

import WhatHappening from "../../components/Dashboard/SupportDesk/WhatHappening.vue";
import ResolveComplaints from "../../components/Dashboard/SupportDesk/ResolveComplaints.vue";
import AverageSpeedOfAnswer from "../../components/Dashboard/SupportDesk/AverageSpeedOfAnswer.vue";
import TicketsChart from "../../components/Dashboard/SupportDesk/TicketsChart/index.vue";
import NumberOfTickets from "../../components/Dashboard/SupportDesk/NumberOfTickets.vue";
import TopPerformerHelpAgent from "../../components/Dashboard/SupportDesk/TopPerformerHelpAgent/index.vue";
import UnresolvedTicketsByPriority from "../../components/Dashboard/SupportDesk/UnresolvedTicketsByPriority.vue";
import CustomerSatisfaction from "../../components/Dashboard/SupportDesk/CustomerSatisfaction.vue";
import UnsolvedTickets from "../../components/Dashboard/SupportDesk/UnsolvedTickets.vue";
import ToDoList from "../../components/Dashboard/SupportDesk/ToDoList/index.vue";

export default defineComponent({
  name: "SupportDeskPage",
  components: {
    WhatHappening,
    ResolveComplaints,
    AverageSpeedOfAnswer,
    TicketsChart,
    NumberOfTickets,
    TopPerformerHelpAgent,
    UnresolvedTicketsByPriority,
    CustomerSatisfaction,
    UnsolvedTickets,
    ToDoList,
  },
});
