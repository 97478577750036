
import { defineComponent } from "vue";
import recentLeads from "./recentLeads.json";

export default defineComponent({
  name: "RecentLeads",
  data() {
    return {
      leads: recentLeads,
    };
  },
});
