
import { defineComponent } from "vue";
import SideBar from "./SideBar.vue";

export default defineComponent({
  name: "CoursesGrid",
  components: {
    SideBar,
  },
});
