
import { defineComponent } from "vue";
import CardsStyle from "./CardsStyle.json";

export default defineComponent({
  name: "CardsStyle",
  data() {
    return {
      cards: CardsStyle,
    };
  },
});
