
import { defineComponent } from "vue";
import mostLead from "./mostLead.json";
import { Tooltip } from "bootstrap";

export default defineComponent({
  name: "MostLead",
  data() {
    return {
      leads: mostLead,
    };
  },
  mounted() {
    // Initialize Bootstrap tooltips
    const tooltipTriggerList = [].slice.call(
      document.querySelectorAll('[data-bs-toggle="tooltip"]')
    );
    tooltipTriggerList.map(function (tooltipTriggerEl) {
      return new Tooltip(tooltipTriggerEl);
    });
  },
});
