
import { defineComponent } from "vue";
import EventSpeakers from "./EventSpeakers.vue";
import TopicToBeCovered from "./TopicToBeCovered.vue";

export default defineComponent({
  name: "EventDetails",
  components: {
    EventSpeakers,
    TopicToBeCovered,
  },
});
