
import { defineComponent } from "vue";
import LeadsInformation from "./LeadsInformation.vue";

export default defineComponent({
  name: "LeadDetails",
  components: {
    LeadsInformation,
  },
});
