
import { defineComponent } from "vue";

import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import CaseList from "@/components/Case/CaseList.vue";

export default defineComponent({
  name: "LeadsPage",
  components: {
    BreadCrumb,
    CaseList,
  },
});
