
import { defineComponent } from "vue";

export default defineComponent({
  name: "SalesByPOSLocation",
  data: function () {
    return {
      sales: [
        {
          name: "Allocated Budget",
          data: [100, 20, 45, 20, 65, 20, 60],
        },
        {
          name: "Actual Spending",
          data: [40, 40, 40, 60, 40, 80, 40],
        },
      ],
      salesByPosLocationChart: {
        chart: {
          height: 385,
          type: "radar",
          toolbar: {
            show: false,
          },
        },
        dataLabels: {
          enabled: false,
        },
        plotOptions: {
          radar: {
            polygons: {
              strokeColors: "#d9e9ef",
              connectorColors: "#d9e9ef",
            },
          },
        },
        legend: {
          show: false,
        },
        colors: ["#6560F0", "#06B48A"],
        markers: {
          size: 5,
          strokeWidth: 0,
        },
        tooltip: {
          y: {
            formatter: function (val) {
              return val;
            },
          },
          style: {
            fontSize: "14px",
            fontFamily: "Red Hat Display, sans-serif",
          },
        },
        xaxis: {
          categories: [
            "Marketing",
            "Sales",
            "Dev",
            "Tech",
            "Support",
            "Social",
            "Admin",
          ],
          labels: {
            show: true,
            style: {
              fontFamily: "Red Hat Display, sans-serif",
              colors: ["#9C9AB6"],
              fontSize: "14px",
              fontWeight: 500,
            },
          },
        },
        yaxis: {
          tickAmount: 7,
          show: false,
          labels: {
            formatter: function (val, i) {
              if (i % 2 === 0) {
                return val;
              } else {
                return "";
              }
            },
            style: {
              fontFamily: "Red Hat Display, sans-serif",
              colors: ["#9C9AB6"],
              fontSize: "14px",
              fontWeight: 500,
            },
          },
        },
      },
    };
  },
});
