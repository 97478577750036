
import { defineComponent } from "vue";
import BreadCrumb from "../../components/Common/BreadCrumb.vue";
import ShadowsContent from "../../components/Utilities/Shadows/ShadowsContent.vue";

export default defineComponent({
  name: "ShadowsPage",
  components: {
    BreadCrumb,
    ShadowsContent,
  },
});
