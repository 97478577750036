
import { defineComponent } from "vue";

import BreadCrumb from "../components/Common/BreadCrumb.vue";

export default defineComponent({
  name: "StarterPage",
  components: {
    BreadCrumb,
  },
});
