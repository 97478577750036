import { reactive } from "vue";

interface StateStore {
  open: boolean;
  getOpen: () => boolean;
  setOpen: (value: boolean) => void;
  onChange: () => void;
}

const stateStore: StateStore = reactive({
  open: false,
  getOpen() {
    return stateStore.open;
  },
  setOpen(value) {
    stateStore.open = value;
  },
  onChange() {
    stateStore.open = !stateStore.open;
  },
});

export default stateStore;
