import {  CaseType } from '@/models/Case';
import api from './api';
import { Pageable, PageableResponse } from 'Pageable';

export function useCaseTypeService() {

    const fetchPage = async (pageable: Pageable): Promise<PageableResponse<CaseType>> => {
        try {
          const response =
            await
              api.get(`case-types?page=${pageable.pageNumber - 1}&size=${pageable.pageSize}&sort=id`
                , { withCredentials: true });
                console.log(response.data);
          return response.data;
        } catch (error) {
          return Promise.reject(error);
        }
      };

      const fetchById =async (caseType:CaseType) => {
        try {
            const response = await api.get('case-types/'+caseType.id, { withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const create =async (caseType:CaseType) => {
        try {
            const response = await api.post('case-types', caseType, { withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const update = async (caseType:CaseType) => {
        try {
            const response = await api.put('case-types/'+caseType.id, caseType, { withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const remove = async (caseType:CaseType) => {
        try {
            const response = await api.delete('case-types/'+caseType.id,{ withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };

    const fetchList = async () => {
        try {
            const response = await api.get('case-types/list', { withCredentials: true });
            return Promise.resolve(response.data);
        } catch (error) {
            return Promise.reject(error);
        }
    };
    return { fetchPage,fetchById,create,update,remove,fetchList }
}