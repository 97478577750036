
import { defineComponent, ref } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Thumbs } from "swiper/modules";

import "swiper/css/free-mode";
import "swiper/css/thumbs";

export default defineComponent({
  name: "ProductDetails",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      products: [
        {
          id: 1,
          image: require("../../../assets/images/products/product-details1.jpg"),
        },
        {
          id: 2,
          image: require("../../../assets/images/products/product-details2.jpg"),
        },
        {
          id: 3,
          image: require("../../../assets/images/products/product-details3.jpg"),
        },
        {
          id: 4,
          image: require("../../../assets/images/products/product-details4.jpg"),
        },
      ],
      itemNumber: 1,
    };
  },
  setup() {
    const thumbsSwiper = ref(null);
    const setThumbsSwiper = (swiper: null) => {
      thumbsSwiper.value = swiper;
    };
    return {
      Autoplay,
      Thumbs,
      thumbsSwiper,
      setThumbsSwiper,
    };
  },
  methods: {
    increment() {
      if (this.itemNumber < 10) {
        this.itemNumber++;
      }
    },
    decrement() {
      if (this.itemNumber > 1) {
        this.itemNumber--;
      }
    },
  },
});
