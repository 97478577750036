
import { defineComponent } from "vue";
import categoriesCourses from "./categoriesCourses.json";

export default defineComponent({
  name: "CategoriesCourses",
  data() {
    return {
      categories: categoriesCourses,
    };
  },
});
