import axiosInstance from "./api";
import { Router } from "vue-router"; 

const setup = (router: Router) => {
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      console.log('Interceptor error:', err);
      const originalConfig = err.config;
      console.log('Original request config:', originalConfig);
      if (originalConfig.url !== "/login" && err.response) {
        // Check for 403 Forbidden error
        if (err.response.status === 403) {
          console.log('Access token expired or invalid. Redirecting to login.');
          localStorage.removeItem('user');
          router.push({ path: '/login' });
        }
      }
      return Promise.reject(err);
    }
  );
};

export default setup;
