
import { defineComponent } from "vue";
import coursesClass from "./coursesClass.json";

export default defineComponent({
  name: "CoursesClass",
  data() {
    return {
      courses: coursesClass,
    };
  },
});
