
import { defineComponent } from "vue";

export default defineComponent({
  name: "ImageUpload",
  data() {
    return {
      selectedFile: null as File | null,
      imageUrl: "",
    };
  },
  methods: {
    handleFileChange(event: Event) {
      const input = event.target as HTMLInputElement;
      this.selectedFile = input.files?.[0] || null;
      this.previewImage();
    },
    previewImage() {
      if (this.selectedFile) {
        const reader = new FileReader();
        reader.onload = (event) => {
          this.imageUrl = event.target?.result as string;
        };
        reader.readAsDataURL(this.selectedFile);
      } else {
        this.imageUrl = "";
      }
    },
    async uploadImage() {
      if (!this.selectedFile) return;

      const formData = new FormData();
      formData.append("image", this.selectedFile);

      try {
        const response = await fetch("/upload-endpoint", {
          method: "POST",
          body: formData,
        });

        if (response.ok) {
          const responseData = await response.json();
          this.imageUrl = responseData.imageUrl; // Assuming the API returns the image URL
        } else {
          console.error("Image upload failed.");
        }
      } catch (error) {
        console.error("Error uploading image:", error);
      }
    },
  },
});
