
import { defineComponent } from "vue";
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, Pagination } from "swiper/modules";

export default defineComponent({
  name: "TodaysEvent",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      courses: [
        {
          id: 1,
          image: require("../../../assets/images/courses/course-mini1.jpg"),
          title: "Fundamental UI/UX Design Course",
          speakers: "2",
          topics: "5",
          start: "7",
          progress: "60",
        },
        {
          id: 2,
          image: require("../../../assets/images/courses/course-mini2.jpg"),
          title: "How To Create Banner Social Media",
          speakers: "6",
          topics: "4",
          start: "2",
          progress: "80",
        },
        {
          id: 3,
          image: require("../../../assets/images/courses/course-mini1.jpg"),
          title: "Fundamental UI/UX Design Course",
          speakers: "3",
          topics: "6",
          start: "5",
          progress: "90",
        },
      ],
    };
  },
  setup() {
    return {
      modules: [Autoplay, Pagination],
    };
  },
});
